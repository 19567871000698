/**
 * When Web Components have been loaded
 * - remove the ready listener
 * - load the app
 * - remove unresolved attribute to prevent FOUC (Flash of Unstyled Content)
 */
window.addEventListener("WebComponentsReady", function onComponentsReady() {
    window.removeEventListener("WebComponentsReady", onComponentsReady, false);
    import("./jobiq").then((app) => app.main()).then(() => document.body.removeAttribute("unresolved"));
}, false);
